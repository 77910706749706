<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }" v-if="currentCategory!=null" >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Sponsor
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Sponsor Name"
            rules="required"
          >
            <b-form-group
              label="Sponsor Name"
              label-for="radio-name"
            >
              <b-form-input
                id="radio-name"
                v-model="currentCategory.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Sponsor Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
 

  <!-- onTapAction Radio Button Group -->
  <validation-provider
    #default="validationContext"
    name="Action"
    rules="required"
  >
    <b-form-group label="Action" label-for="action-radio-group">
      <b-form-radio-group
        id="action-radio-group"
        v-model="currentCategory.onTapAction"
        :state="getValidationState(validationContext)"
        :options="actionOptions"
        name="action-options"
        stacked
      ></b-form-radio-group>

      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Action Url" 
          > 
            <b-form-group
              label="Action Url"
              label-for="streamurl"

            >
              <b-form-input
                id="streamurl"
                v-model="currentCategory.actionUrl"
             
                   placeholder="Action Url"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
    #default="validationContext"
    name="Action"
    rules="required"
  >
    <b-form-group label="Status" label-for="status-radio-group">
      <b-form-radio-group
        id="status-radio-group"
        v-model="currentCategory.status"
        :state="getValidationState(validationContext)"
        :options="statusOptions"
        name="status-options"
        stacked
      ></b-form-radio-group>

      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>


          <b-form-group
              label="Radio Logo"
              label-for="radio-logo"
            >
          <b-media no-body>
          <b-media-aside>
      
          <b-img
            ref="previewEl"
            rounded
            :src="currentCategory.imageUrl"
            height="80"
               width="80"
          />
          <b-media-body class="mt-75 ml-75">
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
           :state="Boolean(file)"
          v-model="file"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="fileSelected"
        />
          </b-media-body>
       
        <!--/ avatar -->
      </b-media-aside>
          </b-media>
          </b-form-group>
        <hr>
        <validation-provider
    #default="validationContext"
    name="Action"
    rules="required"
  >
    <b-form-group label="Sponsor Type" label-for="sponsorType-radio-group">
      <b-form-radio-group
        id="sponsorType-radio-group"
        v-model="currentCategory.sponsorshipType"
        :state="getValidationState(validationContext)"
        :options="sponsorType"
        name="sponsorType-options"
        stacked
      ></b-form-radio-group>

      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
  <b-row >
    <b-col  md="6">
   
          <validation-provider
            #default="validationContext"
            name="Sponsorship Start Date"
             :rules="Boolean(currentCategory.sponsorship_startDate)?'required':null"
            
          >
          
      <b-form-group  label="Sponsorship Start Date"
            label-for="startDate"
            class="mb-2" 
             
                :state="getValidationStateDate(validationContext)">
      
        <flat-pickr
          v-model="currentCategory.sponsorship_startDate" 
          class="form-control"
          input-id="startDate"
         
          :config="disabledDates"
        />
        <b-form-invalid-feedback :state="getValidationStateDate(validationContext)">
           
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>

      </b-form-group>
    </validation-provider>
    </b-col>
    <b-col  md="6">
          <validation-provider
            #default="validationContext"
            name="Sponsorship End Date"
             :rules="Boolean(currentCategory.sponsorship_endDate)?'required':null"
            
          >
          
      <b-form-group  label="Sponsorship End Date"
            label-for="endDate"
            class="mb-2" 
            
                :state="getValidationStateDate(validationContext)">
      
        <flat-pickr
          v-model="currentCategory.sponsorship_endDate" 
          class="form-control"
          input-id="endDate"
         
          :config="disabledDates"
        />
        <b-form-invalid-feedback :state="getValidationStateDate(validationContext)">
           
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>

      </b-form-group>
    </validation-provider>
    </b-col>
    </b-row>
<b-row
v-if="currentCategory.sponsorshipType==1"
>
<b-col md="12">
  <b-form-group  label="Program"
            label-for="startDate"
            class="mb-2" 
             
           >
           

                <ProgramPicker  :itemId="currentCategory.id" 
             :initialCategories="currentCategory.ProgramId"
             
              @updateSelectedPage="programUpdated"
                   />
                  </b-form-group>
              </b-col>

</b-row>

   
<b-row
v-if="currentCategory.sponsorshipType==2"
>
                   <b-col  md="6">
                    <label for="startTime">Start Time</label>
      <b-form-timepicker
        id="startTime"
        v-model="currentCategory.startTime"
        locale="en"
      />    </b-col>  <b-col  md="6">
      <label for="endTime">End Time</label>
      <b-form-timepicker
        id="endTime"
        v-model="currentCategory.endTime"
        locale="en"
      />
                   </b-col>
                  </b-row>  
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="currentCategory==null|| currentCategory.id==null"
            >
                     Add
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="updatecategory"
            v-if="currentCategory!=null&&currentCategory.id!=null"
            >
                      Update 
            </b-button>

              <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
                  class="mr-2"
                  v-if="currentCategory!=null&& currentCategory.id!=null"
              variant="outline-danger"
              @click="deletecategory"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
    BCol,BFormTimepicker, BFormRadioGroup,BFormFile, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BCardText,BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import DepartmentPicker from '../components/DepartmentPicker.vue'
import Attachment from "./Attachment.vue";
import axios from "@axios";
import flatPickr from 'vue-flatpickr-component'
import ProgramPicker from '../component/ProgramPicker.vue';

import moment from 'moment'
export default {
  components: {
    ProgramPicker,
    BFormTimepicker,
    flatPickr,
    BFormRadioGroup,
    BImg,
    BSidebar,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,
    BMedia, BMediaAside, BMediaBody, BLink, BImg,
    Attachment, BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    DepartmentPicker
  },
  computed:{
      startDate: {
        get() {
          if(this.currentCategory.startDate==null||this.currentCategory.startDate==undefined){return null}
          return moment(this.currentCategory.startDate).format('Y-m-d H:i')
        },
        set(value) {
          this.currentCategory.startDate = value
        }
      },
      endDate: {
        get() {
          if(this.currentCategory.endDate==null||this.currentCategory.endDate==undefined){return null}
          return moment(this.currentCategory.endDate).format('Y-m-d H:i')
        },
        set(value) {
          this.currentCategory.endDate = value
        }
      },
      disabledDates(){
      const today = new Date();
     const tomorrow = new Date();

        tomorrow.setDate(today.getDate() + 1);
        console.log(tomorrow);
      var option= { enableTime: true,dateFormat: 'Y-m-d H:i',
      disable:[{from:'1970-08-20',to:tomorrow}]} ;
     return option;

    },

    },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  methods:{
    programUpdated(payload) {
      console.log("page updated");
      console.log(payload);
      this.currentCategory.ProgramId=payload.id;

     
      
    },
     resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },
    refetchData(){
            console.log("Refecth in Uploader");
            this.$emit('refetch-data')
          
        },
        updateData(data)
        {
          console.log(data);
          this.currentCategory.logo=data;
        },
        uploadFile(paylod) {
          return;

        this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
      },
      fileSelected(payload) {
        console.log("file selected");
        if (payload==null||payload==undefined) return;
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("radioimage", this.currentCategory.id);
      
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
 
            this.currentCategory.imageUrl= response.data.url;
            this.isUpdated = true;
            this.isLoading = false;

            this.file = null;
            this.profileFile = null;
           
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
    onDepartmentSelectionChanged(payload)
    {
      console.log("currentCategory selected");
      console.log(payload);
      this.currentCategory.upperCategory=payload.id;
this.currentCategory.Parent=payload ;
    },
     selectionChanged(selectedGroup){
       // console.log(selectedGroup);
 
   
    },
    deletecategory(){
      this.currentCategory.deleted=true;

      this.$store.dispatch('apps-sponsors/deleteradio', this.currentCategory)
        .then((result) => {

          console.log(result);
        this.$emit('refetch-data')
        this.$emit('update:is-add-new-user-sidebar-active', false)
        
      });
    },
    updatecategory(){
     console.log("sent data");
     console.log(this.currentCategory);

      this.$store.dispatch('apps-sponsors/updateblogtag', this.currentCategory)
        .then((result) => {
          console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    },

    submitForm(){
 
    console.log(this.currentCategory);
    var rdId=this.$route.params.id;
console.log("in submitForm");

if(this.currentCategory.RadioId==null||this.currentCategory.RadioId==undefined)
this.currentCategory.RadioId=rdId;

    
       this.$store.dispatch('apps-sponsors/createDepartment', this.currentCategory)
        .then((result) => {
          console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    }
  },
  
  watch: {
     categorydata:   {
        handler:function (n, o)   {
          console.log('new client: ', n);

                  if (n==null||n==undefined)
                  {
                    this.currentCategory={
                      id:null,
                      name:null,
                      description:null,
                       upperCategory: null,
                      Parent:null
                    }
                  } else
                  { 
                    console.log('new client: ', n.selectedCategory);
                  this.currentCategory = n.selectedCategory;
                  }
        

        },
        deep: true
    }
    
},
  
  data() {
    return {
      required,
      alphaNum,
      email,
      sponsorType: [
        { text: 'Global', value: 0 },
        { text: 'Program Attached', value: 1 },
        { text: 'Time Slot', value: 2 },
                                 
      ],
      actionOptions: [
        { text: 'None', value: 0 },
        { text: 'Call', value: 1 },
        { text: 'Navigate', value: 2 },
        { text: 'Whatsapp', value: 3 },
      ],
      statusOptions: [
        { text: 'Active', value: 100 },
        { text: 'Passive', value: 0 },
      
      ],
      countries,
      currentCategory :null,
      profileFile: null,
      file: null,
      isUpdated: false,
          isLoading: false,
    }
  },
   inject: ['categorydata'],
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      upperCategory: '',
      description: '',
      parent:null
       
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      console.log("in onSubmit");

      store.dispatch('apps-sponsors/createDepartment', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)


    const getValidationStateDate  = ({ dirty, validated, required: fieldRequired, changed, valid = null })  => {
     const result = dirty || validated ? valid : null
 
console.log("validation runned: and ruselut ",result);
if (result==null){return false;};

    return !fieldRequired && !changed ? null : result
  
  
    }


    return {
      getValidationStateDate,
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.ql-showHtml:after {
  content: "[source]";
}

</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>